

const cepMask = ({target}) => {
  const lastChar = target.value.slice(-1);
  if(target.value.length === 6 && !target.value.includes('-')) {
    const finalSlice = isNaN(target.value.slice(5)) ? '' : target.value.slice(5);
    target.value = `${target.value.slice(0, 5)}-${finalSlice}`;
  }
  else if(isNaN(lastChar) && target.value.length !== 6) {
    target.value = target.value.slice(0, -1);
  }
  
};

const cpfMask = ({target}) => {
  const lastChar = target.value.slice(-1);
  const valueLen = target.value.length;
  if(valueLen === 4 && !target.value.includes('.')) {
    const finalSlice = isNaN(target.value.slice(3)) ? '' : target.value.slice(3);
    target.value = `${target.value.slice(0, 3)}.${finalSlice}`;
  }
  else if(valueLen === 8 && target.value.charAt(7) !== '.'){
    const finalSlice = isNaN(target.value.slice(7)) ? '' : target.value.slice(7);
    target.value = `${target.value.slice(0, 7)}.${finalSlice}`;
  }
  else if(valueLen === 12 && target.value.charAt(11) !== '-'){
    const finalSlice = isNaN(target.value.slice(11)) ? '' : target.value.slice(11);
    target.value = `${target.value.slice(0, 11)}-${finalSlice}`;
  }
  else if(isNaN(lastChar) && valueLen !== 4 && valueLen !== 8 && valueLen !== 12) {
    target.value = target.value.slice(0, -1);
  }
};


document.addEventListener("DOMContentLoaded", function() {

  let isFormLoaded = false;

  setTimeout(() => {
    const cepNode = document.querySelector('div.field.-inline>input[name=cep]');
    const cpfNode = document.querySelector('div.field.-inline>input[name=cpf]'); 
    if(cepNode && cpfNode) {
      cepNode.addEventListener('keyup', (event) => cepMask(event));
      cpfNode.addEventListener('keyup', (event) => cpfMask(event));
      isFormLoaded = true;
    }
   }, 2500);

  setTimeout(() => { 
    const cepNode = document.querySelector('div.field.-inline>input[name=cep]');
    const cpfNode = document.querySelector('div.field.-inline>input[name=cpf]');
    if(!isFormLoaded && cepNode && cpfNode) {
      cepNode.addEventListener('keyup', (event) => cepMask(event));
      cpfNode.addEventListener('keyup', (event) => cpfMask(event));
      isFormLoaded = true;
    }
  }, 3500);

  setTimeout(() => { 
    const cepNode = document.querySelector('div.field.-inline>input[name=cep]');
    const cpfNode = document.querySelector('div.field.-inline>input[name=cpf]');
    if(!isFormLoaded && cepNode && cpfNode) {
      cepNode.addEventListener('keyup', (event) => cepMask(event));
      cpfNode.addEventListener('keyup', (event) => cpfMask(event));
      isFormLoaded = true;
    }
  }, 4500);

  setTimeout(() => { 
    const cepNode = document.querySelector('div.field.-inline>input[name=cep]');
    const cpfNode = document.querySelector('div.field.-inline>input[name=cpf]');
    if(!isFormLoaded && cepNode && cpfNode) {
      cepNode.addEventListener('keyup', (event) => cepMask(event));
      cpfNode.addEventListener('keyup', (event) => cpfMask(event));
      isFormLoaded = true;
    }
  }, 5500);
  
});
