(function() {
  let navigation = document.querySelector('.navigation ul');
  navigation.addEventListener('scroll', (event) => {
    let start = navigation.scrollLeft;
    let width = navigation.offsetWidth;
    let scrolled = navigation.scrollWidth;
    let dotsAfter = document.querySelector(`.dots-after`);
    let dotsBefore = document.querySelector(`.dots-before`);

    if(start === 0) {
      dotsAfter.style.display = 'table-cell';
      dotsBefore.style.display = 'none';
    } else {
      dotsBefore.style.display = 'table-cell';
    }
    

    if((scrolled-width) === start) {
      dotsAfter.style.display = 'none';
      dotsBefore.style.display = 'table-cell';
    }

  });
})();
