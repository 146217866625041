(function() {
  let faqItems = document.querySelectorAll('.content .list .item');
  let lastClicked = undefined;
  faqItems.forEach( (item) => {
    item.addEventListener('click', (event) => {
      if(lastClicked != undefined) lastClicked.classList.toggle('-opened');
      event.currentTarget.classList.toggle('-opened');
      window.location.hash = event.currentTarget.getAttribute('id');
      lastClicked = event.currentTarget;
    })
  });
})();