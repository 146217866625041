(() => {
  let overlay = document.querySelector('#map .overlay-map');
  let map = document.querySelector('#map iframe');

  console.log(overlay);

  overlay.addEventListener('click', (e) => {
    console.log(this);
    overlay.remove();
    map.style.pointerEvents = 'all';
  })
})();