class Environment {
  constructor(hostname) {
    this.hostname = hostname;
    this.ENV = {
      "development": {
        environment: "development",
        api_base_url: "https://api.ladysoftescolhasegura.com.br"
      },
      "qa": {
        environment: "qa",
        api_base_url: "https://api.ladysoftescolhasegura.com.br"
      },
      "production": {
        environment: "production",
        api_base_url: "https://api.ladysoftescolhasegura.com.br"
      }
    }
  }

  init() {
    let env = "";

    switch (this.hostname) {

      case 'localhost':
          env = "development";
        break;
      
      case 'escolhaladysoft.qa.tomcomunicacao.com.br':
          env = "qa";
        break;
    
      default:
          env = "production";
        break;
    }
    // Forcing QA env
    return this.ENV["qa"];
  }
}

export default Environment;