import {
  TweenMax,
  Circ,
  Back
} from 'gsap';

(function () {
  function init() {
    let videoBackground = document.querySelector('.video-background > video');
    let lines = document.querySelectorAll('.hero-desktop svg > g[id^=linha]');

    lines.forEach((line) => {
      TweenMax.set(line, {
        y: 20,
      });
    });

    animateVideo(videoBackground);
  }

  function animateVideo(_video) {
    TweenMax.delayedCall(1, animateHeader, [_video]);

    TweenMax.to(_video, 2, {
      x: 170,
      ease: Back.easeInOut, onComplete: () => {
        animateHeroHeader();
      }
    });
  }

  function animateHeader(_video) {
    let header = document.querySelector('body > header > h1');
    let header2 = document.querySelector('body > .hero-desktop > header');
    let nav = document.querySelector('body > nav > ul');

    TweenMax.to(nav, .5, {
      y: 0,
      opacity: 1,
      delay: 2,
      ease: Circ.easeOut
    });
    TweenMax.to(header, .5, {
      y: 0,
      opacity: 1,
      delay: 2.4,
      ease: Circ.easeOut,
      onComplete: () => {
        _video.play();
      }
    });
  }

  function animateHeroHeader() {
    let hero = document.querySelector('.hero-desktop');
    let lines = hero.querySelectorAll(' svg > g[id^=linha]');
    let paragraph = hero.querySelector('.paragraph-hero');
    let button = hero.querySelector('#cadastre-hero');
    let formHero = hero.querySelector('#form-hero');
    let counterReverse = 3;
    lines.forEach((line, index) => {
      TweenMax.to(line, .75, {
        y: 0,
        opacity: 1,
        delay: (counterReverse * .3),
        ease: Circ.easeOut
      });

      let rect = line.querySelector('g rect');
      TweenMax.to(rect, .5, {
        width: rect.getAttribute('width'),
        ease: Circ.easeInOut,
        delay: ((counterReverse) * .4)
      });
      counterReverse--;
    });

    TweenMax.to(paragraph, 1, {
      y: 0,
      opacity: 1,
      ease: Circ.easeOut,
      delay: .7
    });

    if(button != null){

      TweenMax.to(button, 1, {
        y: 0,
        opacity: 1,
        ease: Circ.easeOut,
        delay: 1,
        onComplete: () => {
          document.querySelector('body').style.overflow = 'auto';
          let main = document.querySelector('.main');
          let footer = document.querySelector('footer');
          main.classList.add('-ready');
          footer.classList.add('-ready');
          TweenMax.to(main, .5, { opacity: 1 } );
          TweenMax.to(footer, 0, { opacity: 1 } );
        }
      });

    }

    if(formHero != null){

      TweenMax.to(formHero, 1, {
        y: 0,
        opacity: 1,
        ease: Circ.easeOut,
        delay: 1,
        onComplete: () => {
          document.querySelector('body').style.overflow = 'auto';
          let main = document.querySelector('.main');
          let footer = document.querySelector('footer');
          main.classList.add('-ready');
          footer.classList.add('-ready');
          TweenMax.to(main, .5, { opacity: 1 } );
          TweenMax.to(footer, 0, { opacity: 1 } );
        }
      });
    }

  }

  init();

})(TweenMax);