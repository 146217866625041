import Axios from 'axios';
import Environment from './Environment';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Raven.context(() => {
  const environment = new Environment(window.location.hostname);
  window.env = environment.init();
  
  Raven.config('https://4ae9fc54bf1f4cd3be72ed9e746e12b9@sentry.io/1462300').install();
  
  document.addEventListener("DOMContentLoaded", (event) => {
    
    let appHeader = new Vue({
      el: '#header',
      data: {
        available: false,
      }
    });
    
    let appNavigation = new Vue({
      el: '#navigation',
      data: {
        available: false
      }
    });
    
    let appNovidades = new Vue({
      el: '#novidades',
      data: {
        available: false,
        invalidMail: 'E-mail inválido. Por favor, preencha o campo corretamente.',
        emailInUse: 'Este email já foi informado.',
        requiredField: 'Por favor, preencha este campo.',
        recapchaError: 'Erro ao validar o recaptcha. Por favor, tente novamente.',
        authToken: '',
        person: {
          email: "",
          valid_email: true,
          empty_email: false,
          already_registered: false,
          grecaptcha_token: "",
          recaptcha_fail: false,
        },
        steps: {
          step1: true,
          step2: false,
        },
      },
      methods: {
        validateEmail: function (event) {
          let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          this.person.valid_email = reg.test(this.person.email);
          
          let enviarButton = this.$refs.enviar;
          
          if(this.person.email == ""){
            this.person.empty_email = true;
            enviarButton.setAttribute('disabled', 'disabled');
          }else{
            this.person.empty_email = false;
          }
          
          if(!this.person.valid_email){ 
            enviarButton.setAttribute('disabled', 'disabled');
          }
          
          if(!this.person.empty_email && this.person.valid_email){
            enviarButton.removeAttribute("disabled");
          }
          
        },
        send: function (event) {

          let enviarButton = appNovidades.$refs.enviar; 
          enviarButton.innerText = "Enviando...";
          
          event.preventDefault();
          
          grecaptcha.execute('6Lc3jqYUAAAAAAnZWuWFoZvMFu3dwQAAeCZXyV0F', {action: 'homepage'}).then(function(token) {
            
            appNovidades.person.grecaptcha_token = token;
            
            Axios.post(window.env.api_base_url + '/api/v1/newsletter', appNovidades.person, {
              headers: {
                'Authorization': 'bearer ' + appNovidades.authToken
              }
            }).then((response) => {
              
              dataLayer.push({
                'event': 'formulario-enviado'
              });
              
              appNovidades.$emit('goTo', 1, appNovidades.$refs.steps, appNovidades);
              
              
            }).catch((error) => {
              
              if(error.response){
                
                if(error.response.status == "409"){
                  
                  appNovidades.person.already_registered = true;
                  
                }else if(error.response.status == "401"){
                  
                  appNovidades.person.recaptcha_fail = true;
                  
                }
                
              }
              
            }).finally(function () {
              
              event.preventDefault();

              enviarButton.innerText = "Enviar";
              
            });
            
          });
          
        }
      }
    });
    
    let appHeroDesktop = new Vue({
      el: '#hero-desktop',
      data: {
        available: false,
        invalidMail: 'E-mail inválido. Por favor, preencha o campo corretamente.',
        emailInUse: 'Este email já foi informado.',
        requiredField: 'Por favor, preencha este campo.',
        recapchaError: 'Erro ao validar o recaptcha. Por favor, tente novamente.',
        authToken: '',
        person: {
          email: "",
          valid_email: true,
          empty_email: false,
          already_registered: false,
          grecaptcha_token: "",
          recaptcha_fail: false,
        },
        steps: {
          step1: true,
          step2: false,
        },
      },
      methods: {
        validateEmail: function (event) {
          let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          this.person.valid_email = reg.test(this.person.email);
          
          let enviarButton = this.$refs.enviar;
          
          if(this.person.email == ""){
            this.person.empty_email = true;
            enviarButton.setAttribute('disabled', 'disabled');
          }else{
            this.person.empty_email = false;
          }
          
          if(!this.person.valid_email){ 
            enviarButton.setAttribute('disabled', 'disabled');
          }
          
          if(!this.person.empty_email && this.person.valid_email){
            enviarButton.removeAttribute("disabled");
          }
          
          
        },
        send: function (event) {
          
          event.preventDefault();

          let enviarButton = appHeroDesktop.$refs.enviar;

          enviarButton.innerText = "Enviando...";
          
          grecaptcha.execute('6Lc3jqYUAAAAAAnZWuWFoZvMFu3dwQAAeCZXyV0F', {action: 'homepage'}).then(function(token) {
            
            appHeroDesktop.person.grecaptcha_token = token;
            
            Axios.post(window.env.api_base_url + '/api/v1/newsletter', appHeroDesktop.person, {
              headers: {
                'Authorization': 'bearer ' + appHeroDesktop.authToken
              }
            }).then((response) => {
              
              dataLayer.push({
                'event': 'formulario-enviado'
              });
              
              appHeroDesktop.$emit('goTo', 1, appHeroDesktop.$refs.steps, appHeroDesktop);
              
              
            }).catch((error) => {
              
              if(error.response){
                
                if(error.response.status == "409"){
                  
                  appHeroDesktop.person.already_registered = true;
                  
                }else if(error.response.status == "401"){
                  
                  appHeroDesktop.person.recaptcha_fail = true;
                  
                }
                
              }
              
            }).finally(function () {
              
              event.preventDefault();

              enviarButton.innerText = "Enviar";
              
            });
            
          });
          
          
        }
      }
    });
    
    let appHeroMobile = new Vue({
      el: '#hero',
      data: {
        available: false
      }
    });
    
    appNovidades.$on('goTo', (step, el, _this) => {
      
      TweenMax.set(el, {
        opacity: 1
      });
      
      let elStep = _this.$refs['step' + step];
      
      TweenMax.to(elStep, 0.5, {
        delay: 0.01,
        opacity: 0,
        x: "-100%"
      });
      
      _this.steps.step2 = true;
      TweenMax.from(el, 0.5, {
        delay: 0.01,
        ease: Circ.easeInOut,
        opacity: 0,
        x: 300,
      });  
    });
    
    appHeroDesktop.$on('goTo', (step, el, _this) => {
      
      TweenMax.set(el, {
        opacity: 1
      });
      
      let elStep = _this.$refs['step' + step];
      
      TweenMax.to(elStep, 0.5, {
        delay: 0.01,
        opacity: 0,
        x: "-100%"
      });
      
      _this.steps.step2 = true;
      TweenMax.from(el, 0.5, {
        delay: 0.01,
        ease: Circ.easeInOut,
        opacity: 0,
        x: 300,
      });  
    });
    
    Axios.post(window.env.api_base_url + '/api/v1/auth/login', {
      email: "apps@tomcomunicacao.com.br",
      password: "?tM*zhy27e+Yb[nj?mJ4gZbwsDbv"
    })
    .then((response) => {
      
      let authToken = response.data.access_token;
      
      appHeroDesktop.authToken = authToken;
      appNovidades.authToken = authToken;
      
      
    });
    
    
    

    Axios.get(window.env.api_base_url + '/api/v1/participantes/available')
    .then((response) => {
      
      let available = response.data.available;
      
      appHeader.available = available;
      appNavigation.available = available;
      appNovidades.available = available;
      appHeroDesktop.available = available;
      appHeroMobile.available = available;
      
    });
    
    Sentry.init({
      environment: window.env.environment,
      dsn: 'https://4ae9fc54bf1f4cd3be72ed9e746e12b9@sentry.io/1462300',
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true,
        }),
      ],
    });
    
  });
  
});