import Axios from 'axios';
import VMasker from 'vanilla-masker';
import Environment from './Environment';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import {
  TweenMax,
  Circ
} from "gsap/all";

Raven.context(() => {
  const environment = new Environment(window.location.hostname);
  window.env = environment.init();

  Raven.config('https://4ae9fc54bf1f4cd3be72ed9e746e12b9@sentry.io/1462300').install();

  document.addEventListener("DOMContentLoaded", (event) => {

    var app = new Vue({
      mounted: function () {

        if(this.available){

          let {
            cep,
            cpf
          } = this.$refs;
          VMasker(cep).maskPattern("99999-999");
          VMasker(cpf).maskPattern("999.999.999-99");

        }

        Axios.post(this.base_url + '/api/v1/auth/login', {
            email: "apps@tomcomunicacao.com.br",
            password: "?tM*zhy27e+Yb[nj?mJ4gZbwsDbv"
          })
          .then((response) => {
            this.authToken = response.data.access_token;
          })

          Axios.get(this.base_url + '/api/v1/participantes/available')
            .then((response) => {
              this.available = response.data.available;
            });
      },
      el: '#participe',
      data: {
        authToken: '',
        invalidMail: 'E-mail inválido. Por favor, preencha o campo corretamente.',
        emailInUse: 'Este email já foi informado.',
        invalidCPF: 'CPF inválido. Por favor, preencha o campo corretamente.',
        requiredField: 'Por favor, preencha este campo.',
        base_url: window.env.api_base_url,
        step: 1,
        already_registered: false,
        available: false,
        steps: {
          step1: true,
          step2: false,
          step3: false,
          step4: false
        },
        accept: "no",
        endereco: {},
        status: 'Válido para Belo Horizonte e Região Metropolitana',
        person: {
          name: '',
          email: '',
          cpf: '',
          valid_cpf: true,
          valid_email: true,
          empty_email: false,
          empty_cpf: false,
          aceito_termos: true,
          newsletter: "no",
          endereco: {
            logradouro: '',
            numero: '',
            complemento: '',
            cep: '',
            cidade: '',
            uf: ''
          },
          invites: [{
              name: '',
              email: '',
              valid_email: true,
              email_repeated: false,
            },
            {
              name: '',
              email: '',
              valid_email: true,
              email_repeated: false,
            },
            {
              name: '',
              email: '',
              valid_email: true,
              email_repeated: false,
            }
          ]
        },
      },
      methods: {
        validateCPF: function (event) {
          let reg = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
          this.person.valid_cpf = reg.test(this.person.cpf);

          let proximoButton = this.$refs.proximo2;

          if(!this.person.valid_cpf){
            proximoButton.setAttribute('disabled', 'disabled');
          }

          if(this.person.cpf == ""){
            this.person.empty_cpf = true;
            proximoButton.setAttribute('disabled', 'disabled');
          }else{
            this.person.empty_cpf = false;
          }

        },
        validateInviteEmail: function (event) {
          let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          let index = event.target.dataset.index;
          let friend = this.person.invites[index];

          if (friend) {
            friend.valid_email = reg.test(friend.email);

            if(friend.valid_email){

              friend.email_repeated = false;

              this.person.invites.forEach( (e, i) => {

                if(index != i && friend.email == e.email){
                  friend.email_repeated = true;
                }

              });

            }
          }
        },
        validateEmail: function (event) {
          let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          this.person.valid_email = reg.test(this.person.email);

          let proximoButton = this.$refs.proximo2;

          if(!this.person.valid_email){ 
            proximoButton.setAttribute('disabled', 'disabled');
          }

          if(this.person.email == ""){
            this.person.empty_email = true;
            proximoButton.setAttribute('disabled', 'disabled');
          }else{
            this.person.empty_email = false;
          }

        },
        buscar: function () {
          let {
            cep,
            cpf,
            status,
            complemento,
            numero
          } = this.$refs;
          let [range, resto] = this.person.endereco.cep.split('-');
          let intervaloCEP = parseInt(range);
  
          // 33600-000 (testar esse CEP)
          // this.person.endereco = {};
          complemento.setAttribute('readonly', 'readonly');
          numero.setAttribute('readonly', 'readonly');
  
          if (/^[0-9]{5}-[0-9]{3}$/.test(this.person.endereco.cep)) {

            if (intervaloCEP >= 30000 && intervaloCEP <= 35844) {

              cep.classList.remove('-error');
              status.classList.remove('-error');
  
              Axios.get('https://viacep.com.br/ws/' + this.person.endereco.cep + '/json/')
                .then((response) => {
                  let data = response.data;
                  data.complemento = "";
                  if (data.logradouro == "") {
                    this.$refs.logradouro.removeAttribute('readonly');
                  }
                  this.person.endereco = data;
                  complemento.removeAttribute('readonly');
                  numero.removeAttribute('readonly');
                  this.status = 'Yay! Agora preencha o número e o complememento.'
                })
                .catch(function (error) {
                  Raven.captureException(error);
                  cep.classList.add('-error');
                  status.classList.add('-error');
                  complemento.setAttribute('readonly', 'readonly');
                  numero.setAttribute('readonly', 'readonly');
                  // this.status = 'Houve um erro ao processar seu CEP, tente novamente.';
                })
            } else {
              cep.classList.add('-error');
              status.classList.add('-error');
              complemento.setAttribute('readonly', 'readonly');
              numero.setAttribute('readonly', 'readonly');
              this.status = 'Infelizmente este CEP não pertence a Belo Horizonte e região.';
            }
          }
        },
        basicInfo: function () {
          let proximoButton = this.$refs.proximo1;
          if (this.person.numero !== '') proximoButton.removeAttribute('disabled');
        },
        memberOfPromotion: function () {
          if (/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(this.person.cpf)) {
            Axios.post(this.base_url + '/api/v1/participantes/verify-cpf', {
                cpf: this.person.cpf
              }, {
                headers: {
                  'Authorization': 'bearer ' + this.authToken
                }
              })
              .then((response) => {
                this.already_registered = response.data.participante;
  
                let proximoButton = this.$refs.proximo2;
                if (this.person.name !== '' &&
                  this.person.valid_email &&
                  this.person.valid_cpf &&
                  !this.already_registered) {
                  proximoButton.removeAttribute('disabled');
                } else {
                  proximoButton.setAttribute('disabled', 'disabled');
                }
              }).catch((error) => {
                Raven.captureException(error);
              });
          }
        },
        validatePersonalData: function () {

          this.already_registered = false;
          this.memberOfPromotion();
        },
        nextStep: function (event) {
          event.preventDefault();
          let step = parseInt(event.target.dataset.step);
          this.$emit('goTo', step, this.$refs.steps, this);
          this.step++;
        },
        validateInvites: function (event) {

          this.validateInviteEmail(event);

          let enviarButton = this.$refs.enviar;

          if (
              (this.person.invites[0].name !== '' && this.person.invites[0].email !== '' && this.person.invites[0].valid_email == true && this.person.invites[0].email_repeated == false) &&
              (this.person.invites[1].name !== '' && this.person.invites[1].email !== '' && this.person.invites[1].valid_email == true && this.person.invites[1].email_repeated == false) &&
              (this.person.invites[2].name !== '' && this.person.invites[2].email !== '' && this.person.invites[2].valid_email == true && this.person.invites[2].email_repeated == false) &&
              this.accept === 'yes'
            ) 
          {
            enviarButton.removeAttribute('disabled');
          } else {
            enviarButton.setAttribute('disabled', 'disabled');
          }

          
        },
        send: function (event) {
          event.preventDefault();

          Axios.post(this.base_url + '/api/v1/participantes', this.person, {
            headers: {
              'Authorization': 'bearer ' + this.authToken
            }
          }).then((response) => {
            dataLayer.push({
              'event': 'formulario-enviado'
            });
            this.$emit('goTo', 3, this.$refs.steps, this);
            this.step++;
            return;
          }).catch((error) => {

            if(error.response){

              if(error.response.status == "403"){
                // alert("");
              }

              console.log(error.response.status);
            }

            console.log(error);

            this.$refs.enviar.setAttribute('disabled', 'disabled');
            Raven.captureException(error);
          });
        }
      }
    });
  
    app.$on('activateNext', (step, _this) => {
      switch (step) {
        case 1:
          _this.steps.step2 = true;
          _this.steps.step3 = false;
          _this.steps.step4 = false;
          break;
        case 2:
          _this.steps.step3 = true;
          _this.steps.step4 = false;
          break;
        case 3:
          _this.steps.step4 = true;
          break;
        case 4:
          _this.steps.step2 = false;
          _this.steps.step1 = false;
          _this.steps.step3 = false;
          break;
        default:
          break;
      }
    });
  
    app.$on('goTo', (step, el, _this) => {
      let newX = -step * 100 + '%';
      _this.$emit('activateNext', step, _this);
      let elStep = _this.$refs['step' + step];
  
      if (step === 3) {
        if (!_this.person.invites[0].valid_email &&
          !_this.person.invites[1].valid_email &&
          _this.person.invites[2].valid_email) return false;
      }
  
      TweenMax.to(elStep, 0.5, {
        delay: 0.01,
        opacity: 0
      });
      TweenMax.to(el, 0.5, {
        delay: 0.01,
        ease: Circ.easeInOut,
        x: newX
      });
    });
  
    app.$on('backTo', (step, el, _this) => {
      let newX = step * 100 + '%';
      _this.$emit('activateNext', step, _this);
      let elStep = _this.$refs['step' + step];
  
      if (step === 3) {
        if (!_this.person.invites[0].valid_email &&
          !_this.person.invites[1].valid_email &&
          _this.person.invites[2].valid_email) return false;
      }
  
      TweenMax.to(elStep, 0.5, {
        delay: 0.01,
        opacity: 0
      });
      TweenMax.to(el, 0.5, {
        delay: 0.01,
        ease: Circ.easeInOut,
        x: newX
      });
    });
  
    Sentry.init({
      environment: window.env.environment,
      dsn: 'https://4ae9fc54bf1f4cd3be72ed9e746e12b9@sentry.io/1462300',
      integrations: [
        new Integrations.Vue({
          app,
          attachProps: true,
        }),
      ],
    });
  });
});